import React, { useState, useEffect, useCallback } from 'react';

const MultiRangeSlider = ({ minPrice, maxPrice, setMinPrice, setMaxPrice, productType }) => {
  const min = 0;
  const max = productType === 'panel' ? 999 : 49999;
  const [minThumb, setMinThumb] = useState(0);
  const [maxThumb, setMaxThumb] = useState(0);

  const getPercent = useCallback((value) => ((value - min) / (max - min)) * 100, [min, max]);

  const minTrigger = useCallback(() => {
    setMinThumb(getPercent(minPrice));
  }, [minPrice, getPercent]);

  const maxTrigger = useCallback(() => {
    setMaxThumb(100 - getPercent(maxPrice));
  }, [maxPrice, getPercent]);

  useEffect(() => {
    minTrigger();
    maxTrigger();
  }, [minPrice, maxPrice, minTrigger, maxTrigger]);

  const handleMinPriceChange = (value) => {
    if (!isNaN(value) && value >= min && value <= max) {
      setMinPrice(value);
    }
  };

  const handleMaxPriceChange = (value) => {
    if (!isNaN(value) && value >= min && value <= max) {
      setMaxPrice(value);
    }
  };

  return (
    <div className="flex justify-center items-start mt-10 mx-8">
      <div className="relative max-w-xl w-full">
        <div>
          <input
            type="range"
            step="1"
            min={min}
            max={max}
            value={minPrice}
            onChange={e => handleMinPriceChange(Number(e.target.value))}
            className="absolute pointer-events-none appearance-none z-20 h-2 w-full opacity-0 cursor-pointer"
          />
          <input
            type="range"
            step="1"
            min={min}
            max={max}
            value={maxPrice}
            onChange={e => handleMaxPriceChange(Number(e.target.value))}
            className="absolute pointer-events-none appearance-none z-20 h-2 w-full opacity-0 cursor-pointer"
          />

          <div className="relative z-10 h-1">
            <div className="absolute z-10 left-0 right-0 bottom-0 top-0 rounded-md bg-gray-200"></div>
            <div
              className="absolute z-20 top-0 bottom-0 rounded-md bg-[#2196F3]"
              style={{ right: `${maxThumb}%`, left: `${minThumb}%` }}
            ></div>
            <div
              className="absolute z-30 w-4 h-4 top-0.5 left-0 bg-[#1E88E5] rounded-full -mt-2 -ml-1"
              style={{ left: `${minThumb}%` }}
            ></div>
            <div
              className="absolute z-30 w-4 h-4 top-0.5 right-0 bg-[#1E88E5] rounded-full -mt-2 -mr-3"
              style={{ right: `${maxThumb}%`, marginRight: '-8px' }}
            ></div>
          </div>
        </div>

        <div className="flex justify-between items-center pt-5">
          <div className="relative -ml-8">
            <input
              type="text"
              maxLength="5"
              placeholder="od"
              value={minPrice === 0 ? '' : minPrice}
              onChange={e => handleMinPriceChange(e.target.value === '' ? '' : Number(e.target.value))}
              className="px-3 py-2 border border-gray-200 rounded w-24 text-center pr-8"
            />
            <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 text-sm">zł</span>
          </div>
          -
          <div className="relative">
            <input
              type="text"
              maxLength="5"
              placeholder="do"
              value={maxPrice === (productType === 'panel' ? 999 : 49999) ? '' : maxPrice}
              onChange={e => handleMaxPriceChange(e.target.value === '' ? '' : Number(e.target.value))}
              className="px-3 py-2 border border-gray-200 rounded w-24 text-center pr-8"
            />
            <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 text-sm">zł</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiRangeSlider;
