import { useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useProductFilters = (initialFilters = {}, initialSortOrder = '') => {
  const location = useLocation();
  const navigate = useNavigate();

  const [filters, setFilters] = useState(initialFilters);
  const [sortOrder, setSortOrder] = useState(initialSortOrder);

  const pageIndex = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return Number(queryParams.get('page') ?? 1);
  }, [location.search]);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  const handleSortChange = (order) => {
    setSortOrder(order);
  };

  const handlePageChange = (e, value) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('page', value);
    navigate({ search: queryParams.toString() });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return {
    filters,
    sortOrder,
    pageIndex,
    setFilters: handleFilterChange,
    setSortOrder: handleSortChange,
    onPageChange: handlePageChange,
  };
};
