import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Chip from '@mui/material/Chip'
import HomeIcon from '@mui/icons-material/Home'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { emphasize, styled } from '@mui/material/styles'

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[300]
      : theme.palette.grey[800]
  return {
    backgroundColor,
    height: theme.spacing(4),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  }
})

const BreadcrumbsComponent = () => {
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter(x => x)

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      className="pt-4 sm:pt-6 md:pt-8 lg:pt-10"
      sx={{ marginLeft: { xs: 2, sm: 4, md: 6, lg: 8, xl: 10 } }}
    >
      <StyledBreadcrumb
        component={RouterLink}
        to="/"
        label="Strona główna"
        icon={<HomeIcon fontSize="small" />}
        clickable
      />
      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join('/')}`
        const isLast = index === pathnames.length - 1
        return isLast ? (
          <StyledBreadcrumb
            key={to}
            label={value}
          />
        ) : (
          <StyledBreadcrumb
            key={to}
            component={RouterLink}
            to={to}
            label={value}
            clickable
          />
        )
      })}
    </Breadcrumbs>
  )
}

export default BreadcrumbsComponent