import React from 'react';
import { Link } from 'react-router-dom';
import PurchaseButton from './PurchaseButton';

const SimilarProducts = ({ similarProducts, productType }) => {
    if (productType === 'panel') {
    return (
        <div>
            <h2 className="text-center text-lg mb-4 text-blue-600">Inne oferty tego produktu:</h2>
            <div className='flex justify-center px-4'>
                <ul>
                    {similarProducts.map((similarProduct, index) => (
                        <li
                            key={index}
                            className="p-2 mb-8 border-2 border-gray-200 rounded-lg shadow-lg cursor-pointer flex flex-col md:flex-row mx-2"
                        >
                            <Link
                                to={`/panel/${similarProduct.id}`}
                                className="flex-grow flex flex-col md:flex-row"
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                <div className="flex flex-col items-center md:flex-row md:flex-grow">
                                    <img
                                        src={similarProduct.img_url}
                                        alt={similarProduct.name}
                                        className="w-40 h-48 object-cover mx-5 rounded-xl my-6 drop-shadow-lg"
                                    />
                                    <div className="md:mr-4 flex-grow">
                                        <h3 className="font-bold text-lg sm:text-lg md:text-xl md:ml-6 max-w-3xl text-center md:text-left">
                                            {similarProduct.name}
                                        </h3>
                                        <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 text-center text-blue-600 mt-6 text-md sm:text-lg">
                                            <span>Producent</span>
                                            <span>Moc</span>
                                            <span>Dostępność</span>
                                            <span className=" hidden xl:block">Cena za Wp</span>
                                            <span className='hidden lg:block'>Rodzaj</span>
                                        </div>
                                        <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 text-center mt-3">
                                            <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base shadow-md">
                                                {similarProduct.manufacturer} 
                                            </p>
                                            <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base shadow-md">
                                                {similarProduct.power} Wp
                                            </p>
                                            <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base">
                                                {similarProduct.availability}
                                            </p>
                                            <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base hidden xl:block shadow-md">
                                                {similarProduct.price_wp} zł/Wp
                                            </p>
                                            <p className='border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base hidden lg:block shadow-md'>
                                                {similarProduct.type}
                                            </p>
                                        </div>
                                        <div className="flex justify-evenly text-gray-400 mt-12">
                                            <p className="text-sm md:text-base">
                                                Sprzedawca: <span className="font-semibold ml-2 text-sm md:text-base">{similarProduct.seller}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <div className="flex flex-col items-center justify-center mx-6 mt-4">
                                <p className="font-bold text-blue-600 text-3xl whitespace-nowrap">{similarProduct.price} zł</p>
                                <p className="text-sm text-gray-500">{similarProduct.price_netto} zł bez VAT</p>
                                <PurchaseButton link={similarProduct.link} />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
} else if (productType === 'inwerter') {
    return (
        <div>
            <h2 className="text-center text-lg mb-4 text-blue-600">Inne oferty tego produktu:</h2>
            <div className='flex justify-center px-4'>
                <ul>
                    {similarProducts.map((similarProduct, index) => (
                        <li
                            key={index}
                            className="p-2 mb-8 border-2 border-gray-200 rounded-lg shadow-lg cursor-pointer flex flex-col md:flex-row mx-2"
                        >
                            <Link
                                to={`/inwerter/${similarProduct.id}`}
                                className="flex-grow flex flex-col md:flex-row"
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                <div className="flex flex-col items-center md:flex-row md:flex-grow">
                                    <img
                                        src={similarProduct.img_url}
                                        alt={similarProduct.name}
                                        className="w-40 h-48 object-cover mx-5 rounded-xl my-6 drop-shadow-lg"
                                    />
                                    <div className="md:mr-4 flex-grow">
                                        <h3 className="font-bold text-lg sm:text-lg md:text-xl md:ml-6 max-w-3xl text-center md:text-left">
                                            {similarProduct.name}
                                        </h3>
                                        <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 text-center text-blue-600 mt-6 text-md sm:text-lg">
                                            <span>Producent</span>
                                            <span>Moc</span>
                                            <span>Dostępność</span>
                                            <span className=" hidden xl:block">Liczba MPPT</span>
                                            <span className='hidden lg:block'>Rodzaj</span>
                                        </div>
                                        <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 text-center mt-3">
                                            <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base shadow-md">
                                                {similarProduct.manufacturer} 
                                            </p>
                                            <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base shadow-md">
                                                {similarProduct.power} kW
                                            </p>
                                            <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base">
                                                {similarProduct.availability}
                                            </p>
                                            <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base hidden xl:block shadow-md">
                                                {similarProduct.mppt}
                                            </p>
                                            <p className='border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base hidden lg:block shadow-md'>
                                                {similarProduct.type}
                                            </p>
                                        </div>
                                        <div className="flex justify-evenly text-gray-400 mt-12">
                                            <p className="text-sm md:text-base">
                                                Sprzedawca: <span className="font-semibold ml-2 text-sm md:text-base">{similarProduct.seller}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <div className="flex flex-col items-center justify-center mx-6 mt-4">
                                <p className="font-bold text-blue-600 text-3xl whitespace-nowrap">{similarProduct.price} zł</p>
                                <p className="text-sm text-gray-500">{similarProduct.price_netto} zł bez VAT</p>
                                <PurchaseButton link={similarProduct.link} />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
} else if (productType === 'magazyn') {
    return (
        <div>
            <h2 className="text-center text-lg mb-4 text-blue-600">Inne oferty tego produktu:</h2>
            <div className='flex justify-center px-4'>
                <ul>
                    {similarProducts.map((similarProduct, index) => (
                        <li
                            key={index}
                            className="p-2 mb-8 border-2 border-gray-200 rounded-lg shadow-lg cursor-pointer flex flex-col md:flex-row mx-2"
                        >
                            <Link
                                to={`/magazyn/${similarProduct.id}`}
                                className="flex-grow flex flex-col md:flex-row"
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                <div className="flex flex-col items-center md:flex-row md:flex-grow">
                                    <img
                                        src={similarProduct.img_url}
                                        alt={similarProduct.name}
                                        className="w-40 h-48 object-cover mx-5 rounded-xl my-6 drop-shadow-lg"
                                    />
                                    <div className="md:mr-4 flex-grow">
                                        <h3 className="font-bold text-lg sm:text-lg md:text-xl md:ml-6 max-w-3xl text-center md:text-left">
                                            {similarProduct.name}
                                        </h3>
                                        <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 text-center text-blue-600 mt-6 text-md sm:text-lg">
                                            <span>Producent</span>
                                            <span>Pojemność</span>
                                            <span>Dostępność</span>
                                            <span className=" hidden xl:block">Napięcie</span>
                                            <span className='hidden lg:block'>Rodzaj</span>
                                        </div>
                                        <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 text-center mt-3">
                                            <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base shadow-md">
                                                {similarProduct.manufacturer} 
                                            </p>
                                            <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base shadow-md">
                                                {similarProduct.capacity} kWh
                                            </p>
                                            <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base">
                                                {similarProduct.availability}
                                            </p>
                                            <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base hidden xl:block shadow-md">
                                                {similarProduct.voltage}
                                            </p>
                                            <p className='border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base hidden lg:block shadow-md'>
                                                {similarProduct.type}
                                            </p>
                                        </div>
                                        <div className="flex justify-evenly text-gray-400 mt-12">
                                            <p className="text-sm md:text-base">
                                                Sprzedawca: <span className="font-semibold ml-2 text-sm md:text-base">{similarProduct.seller}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <div className="flex flex-col items-center justify-center mx-6 mt-4">
                                <p className="font-bold text-blue-600 text-3xl whitespace-nowrap">{similarProduct.price} zł</p>
                                <p className="text-sm text-gray-500">{similarProduct.price_netto} zł bez VAT</p>
                                <PurchaseButton link={similarProduct.link} />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )};
};

export default SimilarProducts;
