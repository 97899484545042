export const getPanelManufacturerLogoPath = (manufacturer) => {
  const formattedManufacturer = manufacturer.toLowerCase().replace(/\s+/g, '');

  return `/panels_manufacturers/${formattedManufacturer}.jpg`;
};

export const getInverterManufacturerLogoPath = (manufacturer) => {
  const formattedManufacturer = manufacturer.toLowerCase().replace(/\s+/g, '');

  return `/inverters_manufacturers/${formattedManufacturer}.jpg`;
};

export const getMagazineManufacturerLogoPath = (manufacturer) => {
  const formattedManufacturer = manufacturer.toLowerCase().replace(/\s+/g, '');

  return `/magazines_manufacturers/${formattedManufacturer}.jpg`;
};