import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProducts } from '../requests';
import SimilarProducts from '../components/SimilarProducts';
import MoreProductDetails from '../components/MoreProductDetails';
import ProductInfo from '../components/ProductInfo';
import ToggleMoreInfoButton from '../components/ToggleMoreInfoButton';

const PanelDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [showMoreInfo, setShowMoreInfo] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProduct = async () => {
      const fetchedData = await fetchProducts(`get_panel/${id}`);
      setProduct(fetchedData);
      setLoading(false);
    };

    const fetchSimilarProducts = async () => {
      const fetchedSimilarData = await fetchProducts(`similar_products_panels/${id}`);
      setSimilarProducts(fetchedSimilarData);
    };

    fetchProduct();
    fetchSimilarProducts();
  }, [id]);

  const toggleMoreInfo = () => {
    setShowMoreInfo(!showMoreInfo);
  };

  if (loading) {
    return (
      <div className='fixed inset-0 flex justify-center items-center'>
        <l-dot-pulse size="80" speed="1.0" color="#9ca3af"></l-dot-pulse>
      </div>
    )
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <>
    <ProductInfo product={product} productType="panel" />
    <ToggleMoreInfoButton onClick={toggleMoreInfo} isExpanded={showMoreInfo} />
    <MoreProductDetails product={product} showMoreInfo={showMoreInfo} toggleMoreInfo={toggleMoreInfo} />
    <SimilarProducts similarProducts={similarProducts} productType="panel" />
    </>
  );
};

export default PanelDetails;
