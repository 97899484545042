import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const ToggleMoreInfoButton = ({ onClick, isExpanded }) => {
  return (
    <div className='flex justify-center'>
      <button
        onClick={onClick}
        className="text-lg text-center text-gray-400 hover:underline hover:text-gray-400"
      >
        {isExpanded ? (
          <>
            Mniej informacji <FontAwesomeIcon icon={faChevronUp} className='ml-2' />
          </>
        ) : (
          <>
            Więcej informacji <FontAwesomeIcon icon={faChevronDown} className='ml-2' />
          </>
        )}
      </button>
    </div>
  );
};

ToggleMoreInfoButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};

export default ToggleMoreInfoButton