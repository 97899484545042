import React from 'react'
import { Link } from 'react-router-dom'

const Hero = () => {
  return (
    <div className='flex justify-center md:mx-20'>
      <div className='mt-10 md:mt-20 grid grid-cols-1 md:grid-cols-3 grid-rows-3 md:grid-rows-2 gap-4 h-auto md:h-96'>
        <Link to="/panele" className='md:row-span-2 rounded-xl overflow-hidden cursor-pointer'>
          <div className='h-48 md:h-full w-full relative'>
            <img src='panele.png' alt='Panele' className='h-full w-full hover:scale-110 transition duration-300 ease-in-out' />
            <div className='opacity-100 absolute bottom-0 left-1 flex justify-end items-end text-4xl md:text-xl lg:text-4xl text-white p-4 md:p-6'>
              Moduły PV
            </div>
          </div>
        </Link>
        <Link to="/inwertery" className='md:col-span-2 rounded-xl overflow-hidden cursor-pointer'>
          <div className='h-48 md:h-full w-full relative'>
            <img src='falowniki-poprawione.png' alt='Inwertery' className='h-full w-full hover:scale-110 transition duration-300 ease-in-out' />
            <div className='opacity-100 absolute -bottom-3 left-1 flex justify-end items-end text-4xl md:text-xl lg:text-4xl text-white p-4 md:p-6'>
              Falowniki
            </div>
          </div>
        </Link>
        <div className='md:col-start-2 rounded-xl overflow-hidden'>
          <div className='h-48 md:h-full w-full relative'>
            <img src='pompa-czarnobiala.png' alt='Pompy' className='h-full w-full blur-sm' />
            <div className='opacity-100 absolute md:-top-4 md:-right-3 -top-2 -right-1 flex justify-end items-end text-white p-4 md:p-6'>
              Wkrótce...
            </div>
            <div className='opacity-100 absolute -bottom-2 -left-1 flex justify-end items-end text-4xl md:text-xl lg:text-4xl text-white p-4 md:p-6'>
              Pompy Ciepła
            </div>
          </div>
        </div>
        <Link to="/magazyny" className='rounded-xl overflow-hidden'>
          <div className='h-48 md:h-full w-full relative'>
            <img src='magazyny.png' alt='Magazyny' className='h-full w-full hover:scale-110 transition duration-300 ease-in-out' />
            <div className='opacity-100 absolute bottom-0 left-1 flex justify-end items-end text-4xl md:text-xl lg:text-4xl text-white p-4'>
              Magazyny
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default Hero