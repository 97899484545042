import React from 'react'
import { useState } from 'react'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import SortIcon from '@mui/icons-material/Sort'

const sortOptions = [
  { name: 'Cena: rosnąco', value: 'asc', current: false },
  { name: 'Cena: malejąco', value: 'desc', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Sort = ({ onSortChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton
          onClick={toggleMenu}
          className="group inline-flex justify-center text-sm hover:text-gray-400 border border-gray-300 px-2 py-1 rounded-md"
        >
          Sortuj według
          <SortIcon
            fontSize='small'
            className="-mr-1 ml-1 hover:text-gray-400"
            aria-hidden="true"
          />
        </MenuButton>
      </div>

      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {sortOptions.map((option) => (
              <MenuItem key={option.name}>
                {({ active }) => (
                  <button
                    onClick={() => onSortChange(option.value)}
                    className={classNames(
                      option.current ? 'font-medium text-gray-900' : 'text-gray-500',
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm w-full text-left'
                    )}
                  >
                    {option.name}
                  </button>
                )}
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  )
}

export default Sort
