import React from 'react';
import ToggleMoreInfoButton from './ToggleMoreInfoButton';

const MoreProductDetails = ({ product, showMoreInfo, toggleMoreInfo }) => {
  const details = [
    {
      group: 'Column 1',
      items: [
        { label: 'Długość', value: `${product.module_length} mm` },
        { label: 'Szerokość', value: `${product.module_width} mm` },
        { label: 'Grubość', value: `${product.module_thickness} mm` },
      ],
    },
    {
      group: 'Column 2',
      items: [
        { label: 'Gwarancja produktowa', value: `${product.product_warranty} lat` },
        { label: 'Gwarancja na wydajność', value: `${product.eff_warranty} lat` },
        { label: 'Obramowanie', value: `${product.frame_colour}` },
      ],
    },
    {
      group: 'Column 3',
      items: [
        { label: 'Waga', value: `${product.weight} kg` },
        { label: 'Sprawność', value: `${product.efficiency} %` },
        { label: 'Rodzaj', value: `${product.type}` },
      ],
    },
  ];

  const columnClasses = {
    'Column 1': 'flex flex-col text-left px-6 border-b md:border-none pb-4 md:pb-0',
    'Column 2': 'flex flex-col text-left md:border-l md:border-r border-gray-200 px-6 py-4 md:py-0',
    'Column 3': 'flex flex-col text-left px-6 border-t md:border-none pt-4 md:pt-0',
  };

  const rowClasses = (group) =>
    group === 'Column 1' || group === 'Column 3'
      ? 'flex justify-between gap-x-4 text-left'
      : 'flex justify-between gap-x-4 text-left';

  return (
    <div className="flex justify-center text-center mt-4 mb-20 mx-6">
      {/* <ToggleMoreInfoButton onClick={toggleMoreInfo} isExpanded={showMoreInfo} /> */}
      {showMoreInfo && (
        <div className="flex md:flex-row flex-col border-2 rounded-xl py-6 overflow-hidden">
          {details.map((column, index) => (
            <div key={index} className={`flex-1 ${columnClasses[column.group]}`}>
              {column.items.map((item, idx) => (
                <div
                  key={idx}
                  className={`${rowClasses(column.group)} whitespace-nowrap ${idx > 0 ? 'mt-6' : ''}`}
                >
                  <span className="text-blue-600">{item.label}</span>
                  <p className="font-light">{item.value}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MoreProductDetails;
