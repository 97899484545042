import React from 'react'
import PurchaseButton from '../components/PurchaseButton'
import ProductInfoImages from './ProductInfoImages'

const GridRow = ({ label, value, extraClass = "" }) => {
  return (
    <div className={`flex flex-col items-center ${extraClass}`}>
      <span className="text-lg text-blue-600">{label}</span>
      <p className="font-light text-lg border-2 rounded-lg shadow-md px-2 mt-2">{value}</p>
    </div>
  )
}

const ProductInfo = ({ product, productType }) => {
  if (productType === 'panel') {
    return (
      <div className="flex flex-col lg:flex-row justify-center gap-x-10 mb-12 mt-40">
        <ProductInfoImages product={product} productType={productType} />
        <div className="whitespace-nowrap">
          <h1 className="font-semibold whitespace-normal text-center mb-4 sm:text-3xl mt-10 lg:-mt-2 text-xl">{product.name}</h1>
          <div className="flex sm:flex-row flex-col border-2 rounded-xl shadow-lg px-10 py-6 mx-6">
            <div className="grid grid-cols-2 grid-rows-3 sm:grid-rows-2 sm:grid-cols-3 gap-y-6 md:gap-x-20 sm:gap-x-28 sm:px-2">
              <GridRow label="Producent" value={product.manufacturer} />
              <GridRow label="Moc" value={`${product.power} W`} extraClass="col-start-1 row-start-2" />
              <GridRow label="Sprzedawca" value={product.seller} extraClass="col-start-1 row-start-3 sm:col-start-3 sm:row-start-2" />
              <GridRow label="Dostępność" value={`${product.availability} szt.`} extraClass="col-start-2 row-start-1" />
              <GridRow
                label="Cena za Wp"
                value={
                  <>
                    {`${product.price_wp} zł/Wp `}
                    <span className="block text-sm text-gray-400 text-center">(bez VAT)</span>
                  </>
                }
                extraClass="col-start-2 row-start-2"
              />
              <GridRow label="Wysyłka" value={product.shipping} />
            </div>
            <div className="relative text-center mx-auto md:pl-6 sm:pl-12 flex flex-col justify-center sm:my-auto mt-4">
              <p className="text-md text-blue-600">
                od <span className="font-bold text-5xl text-blue-600">{product.price} zł</span>
              </p>
              <p className="text-md text-gray-500">{product.price_netto} zł bez VAT</p>
              <PurchaseButton link={product.link} />
              {/* <p className="text-xs text-gray-400">Wysyłka od</p> */}
            </div>
          </div>
        </div>
      </div>
    )
  } else if (productType === 'inwerter') {
    return (
        <div className="flex flex-col lg:flex-row justify-center gap-x-10 mb-12 mt-40">
        <ProductInfoImages product={product} productType={productType} />
        <div className="whitespace-nowrap">
          <h1 className="font-semibold whitespace-normal text-center mb-4 sm:text-3xl mt-10 lg:-mt-2 text-xl">{product.name}</h1>
          <div className="flex md:flex-row flex-col border-2 rounded-xl shadow-lg px-10 py-6 mx-6">
            <div className="grid grid-cols-2 grid-rows-4 sm:grid-rows-2 sm:grid-cols-3 gap-y-6 gap-x-20 sm:gap-x-28 sm:px-2">
              <GridRow label="Producent" value={product.manufacturer} />
              <GridRow label="Moc" value={`${product.power} kW`} extraClass="col-start-1 row-start-2" />
              <GridRow label="Sprzedawca" value={product.seller} extraClass="col-start-2 row-start-4 sm:col-start-3 sm:row-start-2" />
              <GridRow label="Fazy" value={product.phase} extraClass="col-start-2 row-start-1" />
              <GridRow
                label="Rodzaj"
                value={product.type}
                extraClass="col-start-2 row-start-2"
              />
              <GridRow label="Wysyłka" value={product.shipping} />
              <GridRow label="Dostępność" value={`${product.availability}`} extraClass="col-start-2 row-start-3" />
              <GridRow label="Liczba MPPT" value={product.mppt} extraClass="col-start-1 row-start-3" />
            </div>
            <div className="relative text-center mx-auto md:pl-20 flex flex-col justify-center md:my-auto mt-12">
              <p className="text-md text-blue-600">
                od <span className="font-bold text-5xl text-blue-600">{product.price} zł</span>
              </p>
              <p className="text-md text-gray-500">{product.price_netto} zł bez VAT</p>
              <PurchaseButton link={product.link} />
              {/* <p className="text-xs text-gray-400">Wysyłka od</p> */}
            </div>
          </div>
        </div>
      </div>
    )
  } else if (productType === 'magazyn') {
    return (
      <div className="flex flex-col lg:flex-row justify-center gap-x-10 mb-12 mt-40">
      <ProductInfoImages product={product} productType={productType} />
      <div className="whitespace-nowrap">
        <h1 className="font-semibold whitespace-normal text-center mb-4 sm:text-3xl mt-10 lg:-mt-2 text-xl">{product.name}</h1>
        <div className="flex md:flex-row flex-col border-2 rounded-xl shadow-lg px-10 py-6 mx-6">
          <div className="grid grid-cols-2 grid-rows-4 sm:grid-rows-2 sm:grid-cols-3 gap-y-6 gap-x-20 sm:gap-x-28 sm:px-2">
            <GridRow label="Producent" value={product.manufacturer} />
            <GridRow label="Pojemność" value={`${product.capacity} kWh`} extraClass="col-start-1 row-start-2" />
            <GridRow label="Sprzedawca" value={product.seller} extraClass="col-start-2 row-start-4 sm:col-start-3 sm:row-start-2" />
            <GridRow label="Napięcie" value={product.voltage} extraClass="col-start-2 row-start-1" />
            <GridRow
              label="Rodzaj"
              value={product.type}
              extraClass="col-start-2 row-start-2"
            />
            <GridRow label="Wysyłka" value={product.shipping} />
            <GridRow label="Dostępność" value={`${product.availability}`} extraClass="col-start-2 row-start-3" />
          </div>
          <div className="relative text-center mx-auto md:pl-20 flex flex-col justify-center md:my-auto mt-12">
            <p className="text-md text-blue-600">
              od <span className="font-bold text-5xl text-blue-600">{product.price} zł</span>
            </p>
            <p className="text-md text-gray-500">{product.price_netto} zł bez VAT</p>
            <PurchaseButton link={product.link} />
          </div>
        </div>
      </div>
    </div>
  )
  } else {
    return null
  }
}

export default ProductInfo