import React from 'react';
import { Link } from 'react-router-dom';
import PurchaseButton from './PurchaseButton';

const Product = ({ product, productType }) => (
  <li className="p-2 mb-8 border-2 border-gray-200 rounded-lg shadow-lg cursor-pointer flex flex-col md:flex-row md:mx-auto sm:mx-10">
    <Link
      to={`/${productType}/${product.id}`}
      className="flex-grow flex flex-col md:flex-row"
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <div className="flex flex-col items-center md:flex-row md:flex-grow">
        <img
          src={product.img_url}
          alt={product.name}
          className="w-40 h-48 object-cover mx-5 rounded-xl my-6 drop-shadow-lg"
        />
        <div className="md:mr-4 flex-grow">
          <h3 className="font-bold text-lg sm:text-xl md:text-2xl md:ml-6 max-w-3xl text-center md:text-left">
            {product.name}
          </h3>
          <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 text-center text-blue-600 mt-6 text-md sm:text-lg">
            <span>Producent</span>
            {productType != 'magazyn' ? (
            <span className="hidden lg:block">Moc</span>
            ) : (
            <span className="hidden lg:block">Pojemność</span>
            )}
            <span>Dostępność</span>
            {productType === 'panel' ? (
              <span className="whitespace-nowrap hidden xl:block">Cena za Wp</span>
            ) : productType === 'inwerter' ? (
              <span className="whitespace-nowrap hidden xl:block">Fazy</span>
            ) : productType === 'magazyn' ? (
              <span className="whitespace-nowrap hidden xl:block">Napięcie</span>
            ) : null }
            <span>Rodzaj</span>
          </div>
          <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 text-center mt-3">
            <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base">
              {product.manufacturer}
            </p>
            <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base whitespace-nowrap hidden lg:block">
              {productType === 'panel' ? `${product.power} Wp` : 
               productType === 'inwerter' ? `${product.power} kW` :
               productType === 'magazyn' ? `${product.capacity} kWh` : null}
            </p>
            <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base">
              {product.availability}
            </p>
            {productType === 'panel' ? (
              <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base whitespace-nowrap hidden xl:block">
                {(product.price_netto / product.power).toFixed(2)} zł/Wp
              </p>
            ) : productType === 'inwerter' ? (
              <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base whitespace-nowrap hidden xl:block">
                {product.phase}
              </p>
            ) : productType === 'magazyn' ? (
              <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base whitespace-nowrap hidden xl:block">
                {product.voltage}
              </p>
            ) : null }
            <p className="border-2 rounded-lg m-auto px-2 py-1 text-sm sm:text-base">
              {product.type}
            </p>
          </div>
          <div className="flex justify-evenly text-gray-400 mt-12">
            <p className="text-sm md:text-base">
              Sprzedawca: <span className="font-semibold ml-2 whitespace-nowrap text-sm md:text-base">{product.seller}</span>
            </p>
          </div>
        </div>
      </div>
    </Link>
    <div className="flex flex-col items-center justify-center mx-6 mt-4">
      <p className="font-bold text-blue-600 text-3xl whitespace-nowrap">{product.price} zł</p>
      <p className="text-sm text-gray-500">{product.price_netto} zł bez VAT</p>
      <PurchaseButton link={product.link} />
    </div>
  </li>
);

export default Product;