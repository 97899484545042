import React, { useEffect, useState } from 'react';
import { useProductFilters } from '../hooks/useProductFilters';
import Filters from '../components/Filters';
import Product from '../components/Product';
import Sort from '../components/Sort';
import { useProducts } from '../hooks/useProducts';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { panelFiltersSchema, inverterFiltersSchema, magazineFiltersSchema } from '../filtersSchema';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';

const ProductPage = ({ productType }) => {
  const { filters, sortOrder, pageIndex, setFilters, setSortOrder, onPageChange } = useProductFilters();

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const pageSize = 25;

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [pageIndex, filters, sortOrder]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { products, totalPages } = useProducts(productType, pageIndex, pageSize, filters, sortOrder);
  const filtersSchema = productType === 'panel' 
    ? panelFiltersSchema 
    : productType === 'inwerter' 
    ? inverterFiltersSchema 
    : productType === 'magazyn'
    ? magazineFiltersSchema
    : null;

  return (
    <>
      <div className='flex lg:justify-end lg:w-3/4 gap-20 justify-center'>
        <button
          onClick={handleClickOpen}
          className='text-sm hover:text-gray-400 border border-gray-300 px-2 py-1 rounded-md group inline-flex justify-center 2xl:hidden'
        >
          Filtruj
          <TuneIcon
            fontSize='small'
            className='-mr-1 ml-1 hover:text-gray-400'
            aria-hidden='true'
          />
        </button>
        <Sort onSortChange={setSortOrder} />
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Filtrowanie</DialogTitle>
        <DialogContent>
          <Filters filtersSchema={filtersSchema} onFilterChange={setFilters} productType={productType} />
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} color='primary'>
            Zamknij
          </button>
        </DialogActions>
      </Dialog>

      <div className='flex mt-10'>
        <div className='hidden 2xl:block'>
          <Filters filtersSchema={filtersSchema} onFilterChange={setFilters} productType={productType} />
        </div>
        <div className='flex-1 px-4 lg:px-24'>
          {loading ? (
            <div className='flex justify-center items-center h-full'>
              <l-dot-pulse size='80' speed='1.0' color='#9ca3af'></l-dot-pulse>
            </div>
          ) : (
            products.length === 0 ? (
              <div className='flex flex-col justify-center items-center h-full'>
                <FontAwesomeIcon icon={faBoxOpen} size='5x' className='text-gray-300 mb-4' />
                <p className='text-3xl font-semibold text-gray-300'>Brak wyników</p>
              </div>
            ) : (
              <ul className='mx-auto'>
                {products.map((product) => (
                  <Product key={product.id} product={product} productType={productType} />
                ))}
              </ul>
            )
          )}
        </div>
      </div>

      <Stack spacing={2} className='justify-center mt-10 flex items-center'>
        <Pagination
          count={totalPages}
          page={pageIndex}
          onChange={onPageChange}
          color='primary'
          size='large'
          showFirstButton showLastButton
        />
      </Stack>
    </>
  );
};

export default ProductPage;
