import { useState, useEffect } from 'react';
import { fetchProducts } from '../api/fetchProducts';

export const useProducts = (productType, pageIndex, pageSize, filters, sortOrder) => {
  const [products, setProducts] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const fetchFilteredProducts = async () => {
    const offset = (pageIndex - 1) * pageSize;
    const queryParams = new URLSearchParams({
      limit: pageSize,
      offset,
    });

    if(sortOrder) {
      queryParams.append('order_by_price', sortOrder);
    }

    if (filters.price) {
      queryParams.append('minPrice', filters.price[0]);
      queryParams.append('maxPrice', filters.price[1]);
    }

    if (filters.power) {
      queryParams.append('minPower', filters.power[0]);
      queryParams.append('maxPower', filters.power[1]);
    }

    if (filters.capacity) {
      queryParams.append('minCapacity', filters.capacity[0]);
      queryParams.append('maxCapacity', filters.capacity[1]);
    }

    if (filters.manufacturer?.length > 0) {
      filters.manufacturer.forEach((manufacturer) => {
        queryParams.append('manufacturers', manufacturer);
      });
    }

    if (filters.types?.length > 0) {
      filters.types.forEach((type) => {
        queryParams.append('types', type);
      });
    }

    if (filters.voltage?.length > 0) {
      filters.voltage.forEach((voltage) => {
        queryParams.append('voltage', voltage);
      });
    }

    if (filters.phase?.length > 0) {
      filters.phase.forEach((phase) => {
        queryParams.append('phase', phase);
      });
    }

    // if (filters.frame_colour?.length > 0) {
    //   filters.frame_colour.forEach((colour) => {
    //     queryParams.append('frame_colour', colour);
    //   });
    // }

    const endpoint = productType === 'panel' 
    ? 'panels/' 
    : productType === 'inwerter'
    ? 'inverters/'
    : productType === 'magazyn'
    ? 'magazines/'
    : null;
    
    const url = `${endpoint}?${queryParams}`;

    try {
      const fetchedData = await fetchProducts(url);
      setProducts(fetchedData.items);
      setTotalPages(Math.ceil(fetchedData.count / pageSize));
    } catch (err) {
      console.error('Error fetching products', err);
    }
  };

  useEffect(() => {
    fetchFilteredProducts();
  }, [productType, pageIndex, filters, sortOrder]);

  return {
    products,
    totalPages,
  };
};
