const apiUrl = process.env.REACT_APP_API_URL;

export const fetchProducts = async (url) => {
  try {
    const response = await fetch(`${apiUrl}${url}`);

    if (!response.ok) {
      throw new Error('Failed to fetch data.');
    }

    return await response.json();

  } catch (error) {
    console.error('Error fetching products!', error);
    throw error;
  }
};

// // Fetch details of a single product by ID
// export const fetchProductDetails = async (productId) => {
//   try {
//     const response = await fetch(`${apiUrl}get_panel/${productId}`);
//     if (!response.ok) {
//       throw new Error('Failed to fetch product details.');
//     }
//     return await response.json();
//   } catch (error) {
//     console.error('Error fetching product details!', error);
//     throw error;
//   }
// };

// // Fetch similar products based on a product ID
// export const fetchSimilarProducts = async (productId) => {
//   try {
//     const response = await fetch(`${apiUrl}similar_products_panels/${productId}`);
//     if (!response.ok) {
//       throw new Error('Failed to fetch similar products.');
//     }
//     return await response.json();
//   } catch (error) {
//     console.error('Error fetching similar products!', error);
//     throw error;
//   }
// };