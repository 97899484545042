import React, { useEffect, useState } from 'react'
import Text from '../components/Text'
import Searchbar from '../components/Searchbar'
import Hero from '../components/Hero'

const Home = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, []);

  if (loading) {
    return (
      <div className='fixed inset-0 flex justify-center items-center'>
        <l-dot-pulse size="80" speed="1.0" color="#9ca3af"></l-dot-pulse>
      </div>
    )
  }

  return (
    <>
      <img src="home-logo2.png"
        alt="Home Logo"
        className="mx-auto max-w-xs scale-75 lg:scale-100"
      />
      {/* <Text /> */}
      {/* <Searchbar /> */}
      <Hero />
    </>
  )
}

export default Home