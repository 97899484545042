import React from 'react';
import ProductPage from '../components/ProductPage';
import Breadcrumbs from '../components/Breadcrumbs';

const Magazines = () => {
    return (
        <div className='space-y-10'>
            <Breadcrumbs />
            <ProductPage productType="magazyn" />
        </div>
    )
}

export default Magazines;