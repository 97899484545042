import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProducts } from '../requests';
import SimilarProducts from '../components/SimilarProducts';
import ProductInfo from '../components/ProductInfo';

const InverterDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProduct = async () => {
      const fetchedData = await fetchProducts(`get_inverter/${id}`);
      setProduct(fetchedData);
      setLoading(false);
    };

    const fetchSimilarProducts = async () => {
      const fetchedSimilarData = await fetchProducts(`similar_products_inverters/${id}`);
      setSimilarProducts(fetchedSimilarData);
    };

    fetchProduct();
    fetchSimilarProducts();
  }, [id]);

  if (loading) {
    return (
      <div className='fixed inset-0 flex justify-center items-center'>
        <l-dot-pulse size="80" speed="1.0" color="#9ca3af"></l-dot-pulse>
      </div>
    );
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <>
    <ProductInfo product={product} productType="inwerter" />
    <SimilarProducts similarProducts={similarProducts} productType="inwerter" />
    </>
  );
}

export default InverterDetails