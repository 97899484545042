export const panelManufacturers = {
  "AE Solar": ["aesolar", "Aesolar", "AE Solar"],
  "AIKO": ["Aiko"],
  "Akcome": ["AKCOME"],
  "Astronergy": ["ASTRONERGY", "Astroenergy", "Astro"],
  "Axitec": ["AXI"],
  "Bauer": ["bauer", "BAUER"],
  "Beyondsun": ["beyondsun", "BEYONDSUN"],
  "Bluesun": ["BLUESUN", "bluesun"],
  "Canadian Solar": ["CanadianSolar", "Canadian"],
  "DAH Solar": ["Dah Solar", "DAH", "dahsolar", "DAHSOLAR", "Dahsolar"],
  "DAS Solar": ["DAS SOLAR", "Das Solar", "Dassolar"],
  "Encor": ["ENCOR", "encor", "CorabEncor", "CORAB", "Corab"],
  "FuturaSun": ["FUTURASUN", "futurasun", "Futurasun"],
  "Huasun": ["HUASUN", "huasun"],
  "Hyundai": ["hyundai", "HYUNDAI"],
  "IBC": ["ibc", "Ibc"],
  "JA Solar": ["JA SOLAR", "Ja Solar", "ja solar", "JaSolar", "JASOLAR", "jasolar", "JAM"],
  "Jetion": ["jetion", "JETION"],
  "Jinko Solar": ["JINKO", "Jinko", "jinko", "JKM"],
  "Jolywood": ["JOLYWOOD"],
  "Kensol": ["KENSOL"],
  "LG": ["NeON", "lg"],
  "Leapton Solar": ["LEAPTON SOLAR", "LEAPTON", "leapton", "Leapton"],
  "Ledvance": ["LEDVANCE", "ledvance"],
  "LONGi Solar": ["LONGI", "Longi", "LONGi", "72HIBD"],
  "Luxor Solar": ["LUXOR", "LUXORSOLAR", "luxorsolar", "LuxorSolar", "Luxorsolar", "LUXOR SOLAR", "Luxor"],
  "Meyer Burger": ["Meyer", "MeyerBurger", "Meyerburger"],
  "Phono Solar": ["PHONO", "PhonoSolar", "Phonosolar"],
  "QCells": ["Q-Cells", "Q-CELLS", "Q Cells", "Q CELLS", "Q PEAK", "Q‑CELLS", "Q.PEAK", "QCELLS"],
  "REC": ["Rec", "TwinPeak"],
  "Risen": ["RISEN", "risen"],
  "Saronic": ["SARONIC"],
  "Selfa": ["SELFA", "selfa"],
  "Sharp": ["SHARP", "sharp"],
  "SoliTek": ["Soli Tek", "solitek", "SOLITEK"],
  "SunLink": ["Sunlink", "SUNLINK"],
  "Sunova": ["sunova", "SUNOVA"],
  "SunPower": ["sunpower", "SUNPOWER", "SUN-POWER", "Sun-Power"],
  "Suntech": ["suntech", "SUNTECH"],
  "Swiss Solar": ["SWISS SOLAR"],
  "Tongwei": ["TW Solar", "tongwei", "TONGWEI"],
  "Trina Solar": ["Trina", "TRINA", "Vertex"],
  "Ulica Solar": ["UlicaSolar", "Ulica Solar"],
  "Viessmann": ["VIESSMANN", "viessmann"],
  "V-TAC": ["v-tac", "V-tac", "V-Tac", "VTAC", "vtac"],
  "ZnShine": ["znshine", "Znshine"]
}

export const inverterManufacturers = {
  "Afore": ["afore", "AFORE", "HNS"],
  "APsystems": ["apsystems", "APSYSTEMS", "Apsystems", "ap-systems", "ap-system"],
  "Avrii": ["avrii", "AVRII", "Avri", "avri", "AVRI"],
  "Deye": ["deye", "DEYE", "deyesun", "Deyesun", "DEYESUN", "DEYESun", "deyesun"],
  "Ecoflow": ["ecoflow", "ECOFLOW", "EcoFlow"],
  "Encor": ["encor", "ENCOR", "Corab", "CORAB", "Corabencor", "CorabEncor"],
  "Enphase": ["enphase", "ENPHASE"],
  "FoxESS": ["foxess", "FOXESS", "Foxess", "FoxEss", "Fox", "Foxes"],
  "Fronius": ["fronius", "FRONIUS", "Tauro", "Symo", "Primo"],
  "General Electric": ["general electric", "General-Electric", "GENERAL ELECTRIC"],
  "GoodWe": ["GOODWE", "goodwe", "GODWE", "godwe", "goodwee", "GOODWEE", "Goodwee"],
  "Growatt": ["growatt", "GROWATT", "growat", "GROWAT"],
  "Hoymiles": ["hoymiles", "HOYMILES", "HoyMiles"],
  "Hypontech": ["hypontech", "HYPONTECH", "HyponTech"],
  "Huawei": ["HUAWEI", "huawei", "SUN2000", "Sun2000"],
  "Kehua Tech": ["kehua", "KEHUA", "Kehuatech", "KehuaTech", "kehuatech", "KEHUATECH", "Kehua"],
  "Kostal": ["KOSTAL", "kostal"],
  "KSTAR": ["kstar", "KSG"],
  "Ledvance": ["LEDVANCE", "ledvance"],
  "RCT POWER": ["RCT", "RCTPOWER", "Rctpower", "RCT-Power", "RCT-POWER"],
  "Selfa": ["SELFA", "selfa"],
  "SMA": ["STP", "SMA "],
  "Sofar": ["SOFAR", "sofar", "Sofarsolar", "sofarsolar", "SOFARSOLAR", "SofarSolar"],
  "Solax": ["solax", "SOLAX", "SolaxPower", "Solaxpower", "solaxpower", "Solax-Power", "SOLAXPower", "SOLAX-Power"],
  "SolarEdge": ["solaredge", "Solaredge", "Solar edge", "Solar Edge", "SOLAREDGE", "HD Wave", "solar-edge", "Solar-Edge", "Solar-edge"],
  "Solplanet": ["solplanet", "SOLPLANET", "SolPlanet"],
  "Solis": ["solis", "SOLIS"],
  "Sungrow": ["sungrow", "SUNGROW", "SunGrow"],
  "SunWays": ["Sunways", "sunways", "SUNWAYS"],
  "Tigo": ["tigo", "TIGO"],
  "Victron Energy": ["VICTRON", "Victron", "VictronEnergy", "Victronenergy", "VICTRONENERGY", "Victron-energy", "victron-energy", "Phoenix Inverter", "Smart", "smart", "Phoenix"],
  "VoltPolska": ["SINUS PRO", "voltpolska", "VOLTPOLSKA"]
}

export const panelFiltersSchema = [
  // {
  //   title: 'Cena',
  //   type: 'range',
  //   name: 'price',
  //   min: 0,
  //   max: 1000,
  // },
  // {
  //   title: 'Moc',
  //   type: 'input',
  //   name: 'power',
  //   min: 0,
  //   max: 1000,
  // },
  {
    title: 'Producent',
    type: 'checkbox',
    name: 'manufacturer',
    values: Object.keys(panelManufacturers).reduce((acc, key) => {
      acc[key] = key;
      return acc;
    }, {})
  },
  {
    title: 'Rodzaj',
    type: 'checkbox',
    name: 'types',
    values: {
      Standard: 'Standard',
      'Full Black': 'Full Black',
      Bifacial: 'Bifacial',
    }
  },
  {
    title: 'Obramowanie',
    type: 'checkbox',
    name: 'frame_colour',
    values: {
      'Czarna rama': 'Czarna rama',
      'Srebrna rama': 'Srebrna rama',
    }
  }
];

export const inverterFiltersSchema = [
  {
    title: 'Producent',
    type: 'checkbox',
    name: 'manufacturer',
    values: Object.keys(inverterManufacturers).reduce((acc, key) => {
      acc[key] = key;
      return acc;
    }, {})
  },
  {
    title: 'Rodzaj',
    type: 'checkbox',
    name: 'types',
    values: {
      Sieciowy: 'Sieciowy',
      Hybrydowy: 'Hybrydowy',
      Mikroinwertery: 'Mikroinwertery',
    }
  },
  {
    title: 'Fazy',
    type: 'checkbox',
    name: 'phase',
    values: {
      '1 Fazowy': '1 Fazowy',
      '3 Fazowy': '3 Fazowy',
    }
  },
  {
    title: 'Liczba MPPT',
    type: 'checkbox',
    name: 'mppt',
    values: {
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10',
      11: '11',
      12: '12',
      13: '13',
      14: '14',
      15: '15',
    }
  },
]

export const magazineFiltersSchema = [
  {
    title: 'Producent',
    type: 'checkbox',
    name: 'manufacturer',
    values: Object.keys(inverterManufacturers).reduce((acc, key) => {
      acc[key] = key;
      return acc;
    }, {})
  },
  {
    title: 'Rodzaj',
    type: 'checkbox',
    name: 'types',
    values: {
      "Moduł Bateryjny": 'Moduł Bateryjny',
      "Zestaw z falownikiem": 'Zestaw z falownikiem',
      BMS: 'BMS',
    }
  },
  {
    title: 'Napięcie',
    type: 'checkbox',
    name: 'voltage',
    values: {
      Wysokonapięciowy: 'Wysokonapięciowy',
      Niskonapięciowy: 'Niskonapięciowy',
    }
  }
]