const apiUrl = process.env.REACT_APP_API_URL;

export const fetchProducts = async (url) => {
  try {
    const response = await fetch(`${apiUrl}${url}`);

    if (!response.ok) {
      return [];
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error('There was an error fetching the products!', error);
    return [];
  }
};