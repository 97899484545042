import React from 'react';
import { getPanelManufacturerLogoPath, getInverterManufacturerLogoPath, getMagazineManufacturerLogoPath } from '../utils';

const ProductInfoImages = ({ product, productType }) => {

  const getManufacturerLogoPath = (manufacturer) => {
    console.log(productType)
    return productType === 'inwerter'
    ? getInverterManufacturerLogoPath(manufacturer)
    : productType === 'panel'
    ? getPanelManufacturerLogoPath(manufacturer)
    : productType === 'magazyn'
    ? getMagazineManufacturerLogoPath(manufacturer)
    : null;
  }

  return (
    <div className='relative flex flex-col items-center lg:ml-6'>
      <img
        src={getManufacturerLogoPath(product.manufacturer)}
        alt={`${product.manufacturer} logo`}
        className='object-cover absolute -top-28 scale-50 border-4 drop-shadow-lg rounded-3xl sm:w-72 sm:h-32 w-60 h-28'
      />
      <img
        src={product.img_url}
        alt={product.name}
        className='w-[300px] h-[400px] min-w-[300px] min-h-[400px] object-cover rounded-3xl border drop-shadow-lg'
      />
    </div>
  );
};

export default ProductInfoImages;
