import { Link, useLocation } from 'react-router-dom'
import ToggleThemeButton from './ToggleThemeButton'

const Navbar = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <nav className='h-20 flex items-center justify-between sticky top-0 z-20 mt-2'>
      <div className='md:ml-32 mx-auto w cursor-pointer'>
        {!isHomePage && (
          <Link to="/">
            <img src={`${process.env.PUBLIC_URL}/navLogo.png`} alt='navLogo' className='w-40 h-auto hover:scale-110 transition duration-200 ease-in-out'/>
          </Link>
        )}
      </div>
      {/* <ul className='flex text-center text-lg text-blue-600'>
        <li className='mr-32 hover:scale-110 hover:text-blue-500 transition-all duration-200 ease-in-out cursor-pointer'>
          <Link to="/">Home</Link>
        </li>
        <li className='hover:scale-110 hover:text-blue-500 transition-all duration-200 ease-in-out cursor-pointer'>
          Kontakt
        </li>
      </ul> */}
      <div className='flex justify-between items-center'>
        {/* <div className='flex space-x-20'>
          <Link to="/signup">
            <button className='bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-500 transition-all duration-200 ease-in-out'>
              Sign Up
            </button>
          </Link>
          <Link to="/signin">
            <button className='bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-500 transition-all duration-200 ease-in-out'>
              Sign In
            </button>
          </Link>
        </div>
        <div className='mr-10 ml-20'>
          <ToggleThemeButton />
        </div> */}
      </div>
    </nav>
  );
}

export default Navbar